import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HelicopterService } from '../helicopter/helicopter.service';
import { LocalStorageService } from '../shared/local-storage.service';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { Helicopter } from '../shared/models/helicopter.model';

@Component({
  selector: 'app-helicopter-list',
  templateUrl: './helicopter.component.html',
  styleUrls: ['./helicopter.component.css']
})

export class HelicopterComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output() linkClick: EventEmitter<null> = new EventEmitter<null>();

  displayedColumns: string[] = ['position', 'name', 'serialNumber', 'modelType', 'registration', 'actions'];
  public helicoptersDataSource = new MatTableDataSource<Helicopter>();

  private _helicopterTypes = [];
  private _operators = [];

  constructor(private _helicoptersService: HelicopterService, private _localStorageService: LocalStorageService, private _dialog: MatDialog,
    private _translate: TranslateService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    const userSessionObject = this._localStorageService.getUserLocalStorageObject();
    this._helicoptersService.getHelicopterAttributes(userSessionObject.organizationId).subscribe(attributes => {
      this._helicopterTypes = attributes.helicopterTypes;
      this._operators = attributes.operators;
      this._helicoptersService.getHelicoptersByOrgId(userSessionObject.organizationId.toString()).subscribe();
      this._helicoptersService.helicopters.subscribe(result => {
        this.helicoptersDataSource.data = [];
        this.helicoptersDataSource.data = result;
      });
    });
  }

  ngAfterViewInit() {
    // this.helicoptersDataSource.paginator = this.paginator;
  }

  openConfirmDeleteDialog(elmId: string, index: number): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: this._translate.instant('CONFIRMATIONDIALOG.deleteDataQestion')
    });
    dialogRef.afterClosed().subscribe(clickResult => {
      if (clickResult) {
        let observ: Observable<any>;
        observ = this._helicoptersService.softDeleteHelicopterById(elmId, index);
        observ.subscribe(result => {
          this._snackBar.open(this._translate.instant('GENERAL.deleteSuccessMsg'), this._translate.instant('GENERAL.close'), {
            duration: 3000,
          });
        });
      }
    });
  }
  getHelicopterType(id: number) {
    const type = this._helicopterTypes.filter(t => t.id === id)[0];
    return (type ? type.name : '');
  }
  getOperatorName(id: number) {
    const operator = this._operators.filter(o => o.id === id)[0];
    return (operator ? operator.name : '');
  }
  triggerLinkClick() {
    this.linkClick.emit();
  }

}
