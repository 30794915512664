import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { PlainPasswordDialogComponent } from 'src/app/shared/plain-password-dialog/plain-password-dialog.component';
import { Country, Role } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';
import { PilotService, Pilot } from '../pilot.service';

@Component({
  selector: 'app-update-pilot',
  templateUrl: './update-pilot.component.html',
  styleUrls: ['./update-pilot.component.css']
})
export class UpdatePilotComponent implements OnInit {

  @ViewChild('pilotForm') pilotForm: NgForm;
  public pilotFormGroup: FormGroup;
  public createState = true;
  public countries: Country[];
  public maxBirthDay = new Date();
  public minValueInsert = new Date();
  public minValueUpdate = null;
  public imagePath: string | ArrayBuffer;
  signatureImageToUpload: File = null;

  private _currentTimeStamp = moment().unix();
  private _userSessionObject;
  private _from = '';
  private _usernameEmpty = false;

  constructor(private _formBuilder: FormBuilder, private _pilotService: PilotService,
    private _localStorageService: LocalStorageService, private _snackBar: MatSnackBar,
    private _translate: TranslateService, private _activatedRoute: ActivatedRoute, private _router: Router,
    private _dialog: MatDialog) {

    this._userSessionObject = this._localStorageService.getUserLocalStorageObject();
    const appSessionObject = this._localStorageService.getAppLocalStorageObject();
    this.pilotFormGroup = this._formBuilder.group({
      id: [''],
      firstName: ['', <any>Validators.required],
      lastName: ['', <any>Validators.required],
      dateOfBirth: [''],
      placeOfBirth: [''],
      street: [''],
      city: [''],
      postalCode: [''],
      idCardNumber: [''],
      idCardExpiryDate: [''],
      licenseNumber: [''],
      issueLicenseDate: [''],
      visitDate: [''],
      expiryVisitDate: [''],
      drivingLicense: [''],
      expiryDrivingLicenseDate: [''],
      caPart: [''],
      countryId: [1],
      organizationId: [this._userSessionObject.organizationId],
      username: ['', <any>Validators.required],
      email: ['', Validators.required],
      signaturePath: ['']
    });
    this.countries = appSessionObject.countries;
  }

  ngOnInit(): void {
    const urlParams = this._activatedRoute.snapshot.params;
    if (this._activatedRoute.snapshot.url[2]) {
      this._from = this._activatedRoute.snapshot.url[2].path;
      if ((this._from !== 'fromlist')) { this._from = ''; }
    } else {
      this._from = '';
    }

    this.maxBirthDay = this.minValueInsert;
    if (urlParams.pilotId && this._activatedRoute.snapshot.url[1].path === 'update') {
      this.maxBirthDay = this.minValueUpdate;
      this.createState = false;
      this._pilotService.getPilotById(urlParams.pilotId).subscribe(result => {
        this.pilotFormGroup.patchValue(result);
        if (this.pilotFormGroup.get('signaturePath').value !== '' && this.pilotFormGroup.get('signaturePath').value) {
          this.imagePath = environment.imageServerUrl + this.pilotFormGroup.get('signaturePath').value + '?' + this._currentTimeStamp;
        }
      }, error => {
        this._router.navigate(['/dashboard']);
      });
    }

    if (this.createState) { this._usernameEmpty = true; }

    this.pilotFormGroup.valueChanges.subscribe(object => {
      if (object.firstName.length >= 3 && object.lastName.length >= 3 && this._usernameEmpty) {
        const usernameString = (object.lastName.substring(0, 3) + object.firstName.substring(0, 3)).toLowerCase();
        this.pilotFormGroup.get('username').setValue(usernameString, { emitEvent: false });
        this._usernameEmpty = false;
      }
      if (object.username !== '' && object.username.indexOf(' ') >= 0) {
        const usernameString = object.username.replace(' ', '_').toLowerCase();
        this.pilotFormGroup.get('username').setValue(usernameString, { emitEvent: false });
      }
    });
  }

  onPilotFormSubmit() {
    if (this.pilotForm.valid) {
      const pilotObject = this.pilotForm.value as Pilot;
      if (pilotObject.dateOfBirth !== "" && moment(pilotObject.dateOfBirth).format('YYYY-MM-DD') !== "Invalid date") {
        pilotObject.dateOfBirth = moment(pilotObject.dateOfBirth).format('YYYY-MM-DD');
      } else {
        pilotObject.dateOfBirth = "";
      }
      if (pilotObject.idCardExpiryDate !== "" && moment(pilotObject.idCardExpiryDate).format('YYYY-MM-DD') !== "Invalid date") {
        pilotObject.idCardExpiryDate = moment(pilotObject.idCardExpiryDate).format('YYYY-MM-DD');
      } else {
        pilotObject.idCardExpiryDate = "";
      }
      if (pilotObject.expiryDrivingLicenseDate !== "" && moment(pilotObject.expiryDrivingLicenseDate).format('YYYY-MM-DD') !== "Invalid date") {
        pilotObject.expiryDrivingLicenseDate = moment(pilotObject.expiryDrivingLicenseDate).format('YYYY-MM-DD');
      } else {
        pilotObject.expiryDrivingLicenseDate = "";
      }
      if (pilotObject.expiryVisitDate !== "" && moment(pilotObject.expiryVisitDate).format('YYYY-MM-DD') !== "Invalid date") {
        pilotObject.expiryVisitDate = moment(pilotObject.expiryVisitDate).format('YYYY-MM-DD');
      } else {
        pilotObject.expiryVisitDate = "";
      }
      if (pilotObject.issueLicenseDate !== "" && moment(pilotObject.issueLicenseDate).format('YYYY-MM-DD') !== "Invalid date") {
        pilotObject.issueLicenseDate = moment(pilotObject.issueLicenseDate).format('YYYY-MM-DD');
      } else {
        pilotObject.issueLicenseDate = "";
      }
      if (pilotObject.visitDate !== "" && moment(pilotObject.visitDate).format('YYYY-MM-DD') !== "Invalid date") {
        pilotObject.visitDate = moment(pilotObject.visitDate).format('YYYY-MM-DD');
      } else {
        pilotObject.visitDate = "";
      }

      pilotObject.username = this.pilotFormGroup.get('username').value;
      // if (!this.signatureImageToUpload && this.createState) {
      //   this.pilotFormGroup.get('signaturePath').setErrors({ requiredImage: true });
      //   return;
      // }
      this._pilotService.updatePilot(pilotObject, this.createState).subscribe(result => {
        if (this.signatureImageToUpload) {
          this._pilotService.uploadPilotSignatureImage(
            this.signatureImageToUpload, result.id.toString(), this._userSessionObject.organizationId).subscribe(() => {
              this.showSuccessSnackBar();
              if (this._from === 'fromlist') { this._router.navigate(['/pilots/list']); }
            });
        } else {
          this.showSuccessSnackBar();
          if (this._from === 'fromlist') { this._router.navigate(['/pilots/list']); }
        }
      }, errorCode => {
        if (errorCode.status === 602) {
          this.pilotFormGroup.get('username').setErrors({ alreadyExist: true });
        }
        if (errorCode.status === 657) {
          this.pilotFormGroup.get('email').setErrors({ alreadyExist: true });
        }
      });
    }
  }

  showSuccessSnackBar() {
    this.signatureImageToUpload = null;
    if (this.createState) {
      this.pilotForm.resetForm({ countryId: 1, organizationId: this._userSessionObject.organizationId, pilotRoleId: 1 });
      this.imagePath = null;
    }
    this._snackBar.open(this._translate.instant('GENERAL.savedSuccessMsg'), this._translate.instant('GENERAL.close'), {
      duration: 3000,
    });
  }

  openPlainPasswordDialog(passwordString: string): void {
    this._dialog.open(PlainPasswordDialogComponent, {
      width: '350px',
      data: passwordString
    });
  }

  handleImageFileInput(files: FileList) {
    this.pilotFormGroup.get('signaturePath').setErrors(null);
    if (files.length === 0) { return; }
    if (files.length > 1) { return; }
    const mimeType = files[0].type;
    if (mimeType.match(/[\/.]png$/i) == null && mimeType.match(/[\/.]jpeg$/i) == null && mimeType.match(/[\/.]webp$/i) == null) {
      this.pilotFormGroup.get('signaturePath').setErrors({ wrongFileType: true });
      return;
    }
    const reader = new FileReader();
    this.signatureImageToUpload = files.item(0);
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imagePath = reader.result;
    };
  }

  removeChoosenImage() {
    this.signatureImageToUpload = null;
    this.imagePath = environment.imageServerUrl + this.pilotFormGroup.get('signaturePath').value;
  }

}
