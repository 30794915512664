import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';



enum EndpointRoutes {
  UPDATE_PROFILE = 'profile/update',
  UPDATE_PROFILE_PASSWORD = 'profile/changePassword',
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private _http: HttpClient) { }

  public updateProfileData(firstName: string, lastName: string) {
    return this._http.post<any>(environment.serverUrl + EndpointRoutes.UPDATE_PROFILE, { firstName, lastName }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public updateProfilePassword(oldPassword: string, newPassword: string) {
    return this._http.post<any>(environment.serverUrl + EndpointRoutes.UPDATE_PROFILE_PASSWORD, { oldPassword, newPassword }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }
}
