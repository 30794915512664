import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country, Role } from './shared.service';

class AppLocalStorageObject {
  lang: string;
  countries?: Country[];
  roles?: Role[];
}

export class UserLocalStorageObject {
  email: string;
  sessionToken: string;
  userName: string;
  organizationId: number;
  constructor(email: string, sessionToken: string, userName: string, organizationId: number) {
    this.email = email;
    this.sessionToken = sessionToken;
    this.userName = userName;
    this.organizationId = organizationId;
  }
}


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public userLocalStorageInserted = new BehaviorSubject(this.getUserLocalStorageObject());


  constructor() { }

  private _getLocalStorage(uniqueId: string) {
    return localStorage.getItem(uniqueId);
  }

  private _setLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  /**
   * USER LOCAL STORAGE
   */
  public userLocalStorageExist(): boolean {
    return (this._getLocalStorage(environment.userSessionUniqueId) ? true : false);
  }

  public getUserLocalStorageObject(): UserLocalStorageObject {
    const currentLocalStorage = this._getLocalStorage(environment.userSessionUniqueId);
    return JSON.parse(currentLocalStorage);
  }

  public setUserLocalStorage(key: string, value: any) {
    const localStorageObject: UserLocalStorageObject = JSON.parse(this._getLocalStorage(environment.userSessionUniqueId));
    localStorageObject[key] = value;
    this._setLocalStorage(environment.userSessionUniqueId, JSON.stringify(localStorageObject));
    this.userLocalStorageInserted.next(localStorageObject);
  }

  /**
   * APP LOCAL STORAGE
   */
  public appLocalStorageExist(): boolean {
    return (this._getLocalStorage(environment.appSessionUniqueId) ? true : false);
  }

  public getAppLocalStorageObject(): AppLocalStorageObject {
    const currentLocalStorage = this._getLocalStorage(environment.appSessionUniqueId);
    const object = JSON.parse(currentLocalStorage);
    if (object.countries) {
      object.countries = JSON.parse(object.countries);
    }
    if (object.roles) {
      object.roles = JSON.parse(object.roles);
    }
    return object;
  }

  public setAppLocalStorage(key: string, value: any) {
    let localStorageObject = new AppLocalStorageObject();
    if (this.appLocalStorageExist()) {
      localStorageObject = JSON.parse(this._getLocalStorage(environment.appSessionUniqueId));
    }
    localStorageObject[key] = value;
    this._setLocalStorage(environment.appSessionUniqueId, JSON.stringify(localStorageObject));
  }
}
