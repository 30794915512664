import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Pilot, PilotService } from '../pilot/pilot.service';
import { LocalStorageService } from '../shared/local-storage.service';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-pilot',
  templateUrl: './pilot.component.html',
  styleUrls: ['./pilot.component.css']
})
export class PilotComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output() linkClick: EventEmitter<null> = new EventEmitter<null>();

  displayedColumns: string[] = ['index', 'name', 'username', 'expiryVisitDate', 'actions'];
  public pilotsDataSource = new MatTableDataSource<Pilot>();

  constructor(private _pilotService: PilotService, private _localStorageService: LocalStorageService, private _dialog: MatDialog,
    private _translate: TranslateService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    const userSessionObject = this._localStorageService.getUserLocalStorageObject();
    this._pilotService.getPilotesByOrgId(userSessionObject.organizationId.toString()).subscribe();
    this._pilotService.pilots.subscribe(result => {
      this.pilotsDataSource.data = [];
      this.pilotsDataSource.data = result;
    });

  }

  ngAfterViewInit() {
    this.pilotsDataSource.paginator = this.paginator;
  }

  openConfirmDeleteDialog(elmId: string, index: number): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: this._translate.instant('CONFIRMATIONDIALOG.deleteDataQestion')
    });
    dialogRef.afterClosed().subscribe(clickResult => {
      if (clickResult) {
        let observ: Observable<any>;
        observ = this._pilotService.softDeletePilotById(elmId, index);
        observ.subscribe(result => {
          this._snackBar.open(this._translate.instant('GENERAL.deleteSuccessMsg'), this._translate.instant('GENERAL.close'), {
            duration: 3000,
          });
        });
      }
    });
  }

  openConfirmResetPasswordDialog(pilotId: string) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: this._translate.instant('CONFIRMATIONDIALOG.resetPasswordQuestion')
    });
    dialogRef.afterClosed().subscribe(clickResult => {
      if (clickResult) {
        this._pilotService.resetPilotPassword(pilotId).subscribe(() => {
          this._snackBar.open(this._translate.instant('CONFIRMATIONDIALOG.passwordSuccessResetMsg'),
            this._translate.instant('GENERAL.close'), {
            duration: 3000,
          });
        });
      }
    });
  }

  triggerLinkClick() {
    this.linkClick.emit();
  }

}
