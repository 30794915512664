import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingService } from '../shared/loading-spinner/loading.service';
import { LoginService, LoginReturnError } from './login.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  @ViewChild('loginForm') loginForm: NgForm;
  public loginFormGroup: FormGroup;
  public errorMessage: String;

  constructor(private _formBuilder: FormBuilder, private _loginService: LoginService, private _router: Router,
    private _loadingService: LoadingService, private _translate: TranslateService) {
    if (this._loginService.currentUserDataValue) {
      if (this._loginService.currentUserDataValue.sessionToken) {
        this._router.navigate(['/dashboard']);
      }
    }
    this.loginFormGroup = this._formBuilder.group({
      'username': ['', <any>[Validators.required, Validators.email]],
      'password': ['', <any>Validators.required]
    });
  }

  ngOnInit(): void {
  }



  onLoginFormSubmit() {
    this.loginFormGroup.get('username').setErrors(null);
    if (this.loginForm.valid) {
      this._loginService.loginPost(this.loginForm.value.username.trim(), this.loginForm.value.password.trim()).subscribe(() => {
        this._loginService.userData.subscribe(userData => {
          if (userData) {
            this._router.navigate(['/dashboard']).then(() => {
              this._loadingService.setHttpProgressStatus(false);
            });
          }
        });
      }, (errorObject: LoginReturnError) => {
        console.log(errorObject);
        this.errorMessage = this._translate.instant('LOGIN.' + errorObject.stringCode);
        if (errorObject.stringCode === 'multipleFailedLoginError') {
          if (typeof(errorObject.value) === 'object') {
            this.errorMessage = this._translate.instant('LOGIN.' + errorObject.stringCode).replace('%value%', errorObject.value.waitTime);
          }
        }
        this.loginFormGroup.controls['username'].setErrors({ notValid: true });
      });
    }
  }

}
