import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { combineLatest, forkJoin } from 'rxjs';
import { HelicopterService } from 'src/app/helicopter/helicopter.service';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { Helicopter } from 'src/app/shared/models/helicopter.model';
import { PlainPasswordDialogComponent } from 'src/app/shared/plain-password-dialog/plain-password-dialog.component';
import { environment } from 'src/environments/environment';
import { MaintenanceCompany, MaintenanceService, MaintenanceAccount } from '../maintenance.service';
import { UpdateMaintenanceCompanyDialogComponent } from '../update-maintenance-company-dialog/update-maintenance-company-dialog.component';

@Component({
  selector: 'app-update-maintenance',
  templateUrl: './update-maintenance.component.html',
  styleUrls: ['./update-maintenance.component.css']
})
export class UpdateMaintenanceComponent implements OnInit {

  @ViewChild('maintenanceAccountForm', { static: false }) maintenanceAccountForm: NgForm;

  private _userSession: any;

  public maxBirthDay = new Date();
  public maintenanceStaffFormGroup: FormGroup;
  public maintenanceCompaniesArray: MaintenanceCompany[] = [];
  public updateCompanyEnabled = false;
  public createState = true;
  public signatureImagePath: string | ArrayBuffer;
  public signatureImageToUpload: File = null;
  public stampImagePath: string | ArrayBuffer;
  public stampImageToUpload: File = null;

  public filteredHelicoptersArray: Helicopter[] = [];
  public helicopterArrayCtr: FormControl = new FormControl();

  private _currentTimeStamp = moment().unix();
  private _usernameEmpty = false;
  private _helicoptersArray: Helicopter[] = [];

  constructor(private _maintenanceService: MaintenanceService, private _localStorageService: LocalStorageService,
    private _formBuilder: FormBuilder, private _dialog: MatDialog, private _snackBar: MatSnackBar, private _translate: TranslateService,
    private _activatedRoute: ActivatedRoute, private _router: Router, private _helicoptersService: HelicopterService) {
    this._userSession = this._localStorageService.getUserLocalStorageObject();
    this.maintenanceStaffFormGroup = this._formBuilder.group({
      id: [''],
      username: ['', <any>Validators.required],
      firstName: ['', <any>Validators.required],
      lastName: ['', <any>Validators.required],
      dateOfBirth: [''],
      placeOfBirth: [''],
      phone: [''],
      email: ['', <any>Validators.required],
      lmaNumber: [''],
      lmaExpiryDate: [''],
      freelancer: [false],
      stampImage: [''],
      signatureImage: [''],
      maintenanceCompanyId: [''],
      organizationId: [this._userSession.organizationId],
      helicopters: [[]]
    });
  }

  ngOnInit(): void {
    const urlParams = this._activatedRoute.snapshot.params;
    combineLatest([
      this._maintenanceService.getMaintenanceCompanies(this._userSession.organizationId),
      this._helicoptersService.getHelicoptersByOrgId(this._userSession.organizationId)
    ]).subscribe(result => {
      this.maintenanceCompaniesArray = result[0];
      this._helicoptersArray = result[1];
      if (urlParams.accountId && this._activatedRoute.snapshot.url[1].path === 'update') {
        this.createState = false;
        this._maintenanceService.getMaintenanceAccount(urlParams.accountId).subscribe(maintenanceAccount => {
          this.maintenanceStaffFormGroup.patchValue(maintenanceAccount, { emitEvent: false });
          this.maintenanceStaffFormGroup.patchValue(
            { maintenanceCompanyId: maintenanceAccount.maintenanceCompany.id }, { emitEvent: false });
          if (this.maintenanceStaffFormGroup.get('signatureImage').value !== '' &&
            this.maintenanceStaffFormGroup.get('signatureImage').value) {
            this.signatureImagePath = environment.imageServerUrl +
              this.maintenanceStaffFormGroup.get('signatureImage').value + '?' + this._currentTimeStamp;
          }
          if (this.maintenanceStaffFormGroup.get('stampImage').value !== '' &&
            this.maintenanceStaffFormGroup.get('stampImage').value) {
            this.stampImagePath = environment.imageServerUrl +
              this.maintenanceStaffFormGroup.get('stampImage').value + '?' + this._currentTimeStamp;
          }
          this._updateFilteredHelicopterArray();
        }, () => {
          this._router.navigate(['/dashboard']);
          return;
        });
      }
    });

    this._maintenanceService.maintenanceCompanyInserted.subscribe(company => {
      if (company) {
        this.maintenanceCompaniesArray.push(company);
        this.maintenanceStaffFormGroup.get('maintenanceCompanyId').setValue(company.id);
      }
    });

    this._maintenanceService.maintenanceCompanyUpdated.subscribe(companies => {
      this.maintenanceCompaniesArray = [];
      if (companies.length > 0) {
        this.maintenanceCompaniesArray = companies;
      }
    });

    if (this.createState) { this._usernameEmpty = true; }

    this.maintenanceStaffFormGroup.valueChanges.subscribe(object => {
      if (object.firstName.length >= 3 && object.lastName.length >= 3 && this._usernameEmpty) {
        const usernameString = (object.lastName.substring(0, 3) + object.firstName.substring(0, 3)).toLowerCase();
        this.maintenanceStaffFormGroup.get('username').setValue(usernameString, { emitEvent: false });
        this._usernameEmpty = false;
      }
      if (object.username !== '' && object.username.indexOf(' ') >= 0) {
        const usernameString = object.username.replace(' ', '_').toLowerCase();
        this.maintenanceStaffFormGroup.get('username').setValue(usernameString, { emitEvent: false });
      }
    });

    this.helicopterArrayCtr.valueChanges.subscribe((helicopterId: number) => {
      const assignedHelicopters = this.maintenanceStaffFormGroup.get('helicopters').value as Array<number>;
      assignedHelicopters.push(helicopterId);
      this.maintenanceStaffFormGroup.patchValue({ helicopters: assignedHelicopters }, { emitEvent: false });
      // this.helicopterArrayCtr.setValue(null, { emitEvent: false });
      this._updateFilteredHelicopterArray();
    });


    this.maintenanceStaffFormGroup.get('maintenanceCompanyId').valueChanges.subscribe(id => {
      this.updateCompanyEnabled = (id !== '' ? true : false);
    });
  }

  onFormSubmit() {
    if (this.maintenanceStaffFormGroup.valid) {
      const object = { ... this.maintenanceStaffFormGroup.value } as MaintenanceAccount;

      if (object.dateOfBirth !== "" && moment(object.dateOfBirth).format('YYYY-MM-DD') !== "Invalid date") {
        object.dateOfBirth = moment(object.dateOfBirth).format('YYYY-MM-DD');
      } else {
        object.dateOfBirth = "";
      }
      if (object.lmaExpiryDate !== "" && moment(object.lmaExpiryDate).format('YYYY-MM-DD') !== "Invalid date") {
        object.lmaExpiryDate = moment(object.lmaExpiryDate).format('YYYY-MM-DD');
      } else {
        object.lmaExpiryDate = "";
      }
      object.freelancer = (this.maintenanceStaffFormGroup.get('freelancer').value === true ? 1 : 0);
      if (!this.signatureImageToUpload && this.createState) {
        this.maintenanceStaffFormGroup.get('signatureImage').setErrors({ requiredImage: true });
        // return;
      }
      if (!this.stampImageToUpload && this.createState) {
        this.maintenanceStaffFormGroup.get('stampImage').setErrors({ requiredImage: true });
        // return;
      }
      const accountObs = (!object.id ? this._maintenanceService.insertMaintenanceAccount(object) :
        this._maintenanceService.updateMaintenanceAccount(object));
      accountObs.subscribe(result => {
        if (this.signatureImageToUpload || this.stampImageToUpload) {
          const observArray = [];
          if (this.signatureImageToUpload) {
            observArray.push(this._maintenanceService.uploadImage(
              this.signatureImageToUpload, result.id.toString(), this._userSession.organizationId, 'signature'));
          }
          if (this.stampImageToUpload) {
            observArray.push(this._maintenanceService.uploadImage(
              this.stampImageToUpload, result.id.toString(), this._userSession.organizationId, 'stamp'));
          }
          forkJoin(observArray).subscribe(() => {
            this.showSuccessSnackBar(result.password);
          });
        } else {
          this.showSuccessSnackBar(result.password);
        }
      }, errorCode => {
        if (errorCode.status === 602) {
          this.maintenanceStaffFormGroup.get('username').setErrors({ alreadyExist: true });
        }
      });
    }
  }

  getHelicopterRegistrationMarkById(helicopterId: number): string {
    if (!helicopterId) { return; }
    return this._helicoptersArray.filter(h => h.id === helicopterId)[0].registrationMark;
  }

  showSuccessSnackBar(password?: string) {
    if (this.createState) {
      this.signatureImageToUpload = null;
      this.stampImageToUpload = null;
      this.maintenanceAccountForm.resetForm({
        freelancer: false,
        organizationId: this._userSession.organizationId,
        maintenanceCompanyId: '',
        username: '',
        firstName: '',
        lastName: '',
      });
      this.signatureImagePath = null;
      this.stampImagePath = null;
    }
    this._router.navigate(['/maintenance', 'list']);
    this._snackBar.open(this._translate.instant('GENERAL.savedSuccessMsg'), this._translate.instant('GENERAL.close'), {
      duration: 3000,
    });
  }

  openUpdateCompanyDialog(newCompanyAction: boolean) {
    this._dialog.open(UpdateMaintenanceCompanyDialogComponent, {
      disableClose: true,
      data: {
        companyId: (newCompanyAction ? null : this.maintenanceStaffFormGroup.get('maintenanceCompanyId').value),
        organizationId: this._userSession.organizationId
      }
    });
  }

  handleSignatureImageFileInput(files: FileList) {
    this.maintenanceStaffFormGroup.get('signatureImage').setErrors(null);
    if (files.length === 0) { return; }
    if (files.length > 1) { return; }
    const mimeType = files[0].type;
    if (mimeType.match(/[\/.]png$/i) == null && mimeType.match(/[\/.]jpeg$/i) == null && mimeType.match(/[\/.]webp$/i) == null) {
      this.maintenanceStaffFormGroup.get('signatureImage').setErrors({ wrongFileType: true });
      return;
    }
    const reader = new FileReader();
    this.signatureImageToUpload = files.item(0);
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.signatureImagePath = reader.result;
    };
  }

  handleStampImageFileInput(files: FileList) {
    this.maintenanceStaffFormGroup.get('stampImage').setErrors(null);
    if (files.length === 0) { return; }
    if (files.length > 1) { return; }
    const mimeType = files[0].type;
    if (mimeType.match(/[\/.]png$/i) == null && mimeType.match(/[\/.]jpeg$/i) == null && mimeType.match(/[\/.]webp$/i) == null) {
      this.maintenanceStaffFormGroup.get('stampImage').setErrors({ wrongFileType: true });
      return;
    }
    const reader = new FileReader();
    this.stampImageToUpload = files.item(0);
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.stampImagePath = reader.result;
    };
  }

  removeChoosenImage(signature: boolean) {
    if (signature) {
      this.signatureImageToUpload = null;
      this.signatureImagePath = environment.imageServerUrl + this.maintenanceStaffFormGroup.get('signatureImage').value;
    } else {
      this.stampImageToUpload = null;
      this.stampImagePath = environment.imageServerUrl + this.maintenanceStaffFormGroup.get('stampImage').value;
    }
  }

  removeAssignedHelicopter(helicopterId: number, index: number): void {
    const assignedHelicopters = this.maintenanceStaffFormGroup.get('helicopters').value as Array<number>;
    assignedHelicopters.splice(index, 1);
    this.maintenanceStaffFormGroup.patchValue({ helicopters: assignedHelicopters }, { emitEvent: false });
    this.helicopterArrayCtr.setValue(null, { emitEvent: false });
    this._updateFilteredHelicopterArray();
  }

  private _updateFilteredHelicopterArray(): void {
    const array = this.maintenanceStaffFormGroup.get('helicopters').value as Array<number>;
    this.filteredHelicoptersArray = this._helicoptersArray.filter(h => array.indexOf(h.id) === -1);
    if (this.filteredHelicoptersArray.length === 0) {
      this.helicopterArrayCtr.disable({ emitEvent: false });
    } else {
      this.helicopterArrayCtr.enable({ emitEvent: false });
    }
  }
}
