import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Helicopter } from '../shared/models/helicopter.model';

export class HelicopterImage { id: number; name: string; path: string; type: number; }

enum EndpointRoutes {
  INSERT_HELICOPTER = 'helicopter/insert',
  UPDATE_HELICOPTER = 'helicopter/update',
  GET_HELICOPTER = 'helicopter/get',
  GET_MANUFACTURER = 'manufacturer/get',
  DELETE_HELICOPTER = 'helicopter/delete',
  GET_HELICOPTER_ATTRIBUTES = 'helicopter/attributes/get',
  UPLOAD_PICTURE = 'helicopter/upload',
  UPLOAD_FILE = 'helicopter/uploadFile',
  GET_IMAGES = 'helicopter/getHelicopterImages',
  GET_FILES = 'helicopter/getHelicopterFiles',
  DELETE_IMAGE = 'helicopter/deleteImage',
  DELETE_FILE = 'helicopter/deleteFile',
  MAKE_MASS_BALANCE_IMAGE = 'helicopter/makeMassBalanceImage',
  UPDATE_HELICOPTER_OPERATOR = '',
  INSERT_HELICOPTER_OPERATOR = '',
}

@Injectable({
  providedIn: 'root'
})
export class HelicopterService {

  private _helicopters: Helicopter[] = [];

  public helicopters: Subject<Helicopter[]> = new Subject();
  public onHelicopterImagesChange: Subject<void> = new Subject();
  public onHelicopterFilesChange: Subject<void> = new Subject();

  private _getHelicopters() {
    return this._helicopters.slice();
  }

  constructor(private _http: HttpClient) { }

  public getHelicopterById(heliId: string) {
    let params = new HttpParams();
    params = params.append('id', heliId);
    return this._http.get<Helicopter>(environment.serverUrl + EndpointRoutes.GET_HELICOPTER, {
      params: params
    }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public updateHelicopter(heliObject: Helicopter, createHeli: boolean): Observable<Helicopter> {
    if (createHeli) {
      return this._http.put<Helicopter>(environment.serverUrl + EndpointRoutes.INSERT_HELICOPTER, { helicopter: heliObject }).pipe(
        map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
    } else {
      return this._http.post<Helicopter>(environment.serverUrl + EndpointRoutes.UPDATE_HELICOPTER, { helicopter: heliObject }).pipe(
        map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
    }
  }

  public getHelicoptersByOrgId(orgId: string) {
    let params = new HttpParams();
    params = params.append('organizationId', orgId);
    return this._http.get<Helicopter[]>(environment.serverUrl + EndpointRoutes.GET_HELICOPTER, { params: params }).pipe(
      map(responseData => {
        this._helicopters = responseData;
        this.helicopters.next(responseData);
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public softDeleteHelicopterById(heliId: string, arrayIndex: number) {
    let params = new HttpParams();
    params = params.append('id', heliId);
    return this._http.delete<any>(environment.serverUrl + EndpointRoutes.DELETE_HELICOPTER, { params: params }).pipe(
      map(responseData => {
        this._helicopters.splice(arrayIndex, 1);
        this.helicopters.next(this._getHelicopters());
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public getHelicopterAttributes(orgId: number) {
    let params = new HttpParams();
    params = params.append('organizationId', orgId.toString());
    return this._http.get<any>(environment.serverUrl + EndpointRoutes.GET_HELICOPTER_ATTRIBUTES, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public uploadHelicopterImage(image: File, helicopterId: string, useImageForTablet: boolean) {
    const formData = new FormData();
    formData.append('helicopterId', helicopterId);
    formData.append('type', (useImageForTablet ? '1' : '2'));
    formData.append('image', image);
    return this._http.post<any>(environment.serverUrl + EndpointRoutes.UPLOAD_PICTURE, formData).pipe(
      map(responseData => {
        this.onHelicopterImagesChange.next();
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public uploadHelicopterFile(file: File, helicopterId: string) {
    const formData = new FormData();
    formData.append('helicopterId', helicopterId);
    formData.append('type', '2');
    formData.append('fileName', file.name.replace(/\.[^/.]+$/, ""));
    formData.append('file', file);
    return this._http.post<any>(environment.serverUrl + EndpointRoutes.UPLOAD_FILE, formData).pipe(
      map(responseData => {
        this.onHelicopterFilesChange.next();
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes => {
          return throwError(errorRes);
        })
      })
    )
  }

  public getHelicoptersImages(heliId: number) {
    let params = new HttpParams();
    params = params.append('helicopterId', heliId.toString());
    return this._http.get<any>(environment.serverUrl + EndpointRoutes.GET_IMAGES, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public getHelicoptersFiles(heliId: number) {
    let params = new HttpParams();
    params = params.append('helicopterId', heliId.toString());
    return this._http.get<any>(environment.serverUrl + EndpointRoutes.GET_FILES, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    )
  }

  public deleteHelicopterImage(imgId: number) {
    let params = new HttpParams();
    params = params.append('id', imgId.toString());
    return this._http.delete<any>(environment.serverUrl + EndpointRoutes.DELETE_IMAGE, { params: params }).pipe(
      map(responseData => {
        this.onHelicopterImagesChange.next();
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public deleteHelicopterFiles(fileId: number) {
    console.log(fileId);
    let params = new HttpParams();
    params = params.append('id', fileId.toString());
    return this._http.delete<any>(environment.serverUrl + EndpointRoutes.DELETE_FILE, { params: params }).pipe(
      map(responseData => {
        this.onHelicopterFilesChange.next();
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    )
  }

  public setMassBalanceImage(imageId: number, helicopterId: number) {
    return this._http.post<HelicopterImage[]>(environment.serverUrl + EndpointRoutes.MAKE_MASS_BALANCE_IMAGE,
      { id: imageId, helicopterId }).pipe(
        map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
}
