import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HelicopterComponent } from './helicopter/helicopter.component';
import { UpdateHelicopterComponent } from './helicopter/update-helicopter/update-helicopter.component';
import { LoginComponent } from './login/login.component';
import { MaintenanceListComponent } from './maintenance/maintenance-list/maintenance-list.component';
import { UpdateMaintenanceComponent } from './maintenance/update-maintenance/update-maintenance.component';
import { UpdateOrganizationComponent } from './organization/update-organization/update-organization.component';
import { PilotComponent } from './pilot/pilot.component';
import { UpdatePilotComponent } from './pilot/update-pilot/update-pilot.component';
import { UpdateProfileComponent } from './profile/update-profile/update-profile.component';
import { AuthGuard } from './shared/_helpers/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: '', component: AppLayoutComponent, canActivate: [AuthGuard], children: [
      { path: 'dashboard', canActivate: [AuthGuard], component: DashboardComponent },
      { path: 'pilots/list', canActivate: [AuthGuard], component: PilotComponent },
      { path: 'pilots/create', canActivate: [AuthGuard], component: UpdatePilotComponent },
      { path: 'pilots/create/fromlist', canActivate: [AuthGuard], component: UpdatePilotComponent },
      { path: 'pilots/update/:pilotId', canActivate: [AuthGuard], component: UpdatePilotComponent },
      { path: 'pilots/update/fromlist/:pilotId', canActivate: [AuthGuard], component: UpdatePilotComponent },
      { path: 'helicopters/list', canActivate: [AuthGuard], component: HelicopterComponent },
      { path: 'helicopters/create', canActivate: [AuthGuard], component: UpdateHelicopterComponent },
      { path: 'helicopters/create/fromlist', canActivate: [AuthGuard], component: UpdateHelicopterComponent },
      { path: 'helicopters/update/:heliId', canActivate: [AuthGuard], component: UpdateHelicopterComponent },
      { path: 'helicopters/update/fromlist/:heliId', canActivate: [AuthGuard], component: UpdateHelicopterComponent },
      { path: 'organization/update/:orgId', canActivate: [AuthGuard], component: UpdateOrganizationComponent },
      { path: 'profile', canActivate: [AuthGuard], component: UpdateProfileComponent },
      { path: 'maintenance/list', canActivate: [AuthGuard], component: MaintenanceListComponent },
      { path: 'maintenance/create', canActivate: [AuthGuard], component: UpdateMaintenanceComponent },
      { path: 'maintenance/update/:accountId', canActivate: [AuthGuard], component: UpdateMaintenanceComponent }
    ]
  },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
