import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class Country {
  id: number;
  name: string;
  code: string;
}

export class Role {
  id: number;
  name: string;
  abbreviation: string;
  status: number;
}

enum EndpointRoutes {
  GET_COUNTRIES = 'country/all',
  GET_PILOT_ROLES = 'pilotRoles/get'
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public sidenavToggle: EventEmitter<null> = new EventEmitter(null);

  constructor(private _http: HttpClient) { }

  public getCountries(): Observable<Country[]> {
    return this._http.get<Country[]>(environment.serverUrl + EndpointRoutes.GET_COUNTRIES).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public getPilotRoles(): Observable<Role[]> {
    return this._http.get<Role[]>(environment.serverUrl + EndpointRoutes.GET_PILOT_ROLES).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }


  checkIfFormGroupHasError(formGroup: FormGroup): boolean {
    let result = false;
    Object.keys(formGroup.controls).forEach(key => {
      const controlErrors: ValidationErrors = formGroup.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          result = true;
        });
      }
    });
    return result;
  }

  getFormGroupErrorsArray(formGroup: FormGroup): { key: string, error: { [errorKey: string]: boolean } }[] {
    const result = [];
    Object.keys(formGroup.controls).forEach(key => {
      const controlErrors: ValidationErrors = formGroup.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          const errorObject = {};
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          errorObject[keyError] = controlErrors[keyError];
          result.push({ key, error: errorObject });
        });
      }
    });
    return result;
  }
}
