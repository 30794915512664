import { Pipe, PipeTransform } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { Role } from '../shared.service';

@Pipe({
  name: 'pilotRole'
})
export class PilotRolePipe implements PipeTransform {

  constructor(private _localStorageService: LocalStorageService) { }

  transform(roleId: number, key: string): string {
    if (roleId) {
      const roles: Role[] = this._localStorageService.getAppLocalStorageObject().roles;
      return roles.filter(role => role.id === roleId)[0][key];
    } else {
      return 'N/D';
    }
  }

}
