import { Component, ElementRef, OnInit, QueryList, Sanitizer, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { HelicopterAttributes } from 'src/app/shared/models/helicopter-attribute.model';
import { Helicopter } from 'src/app/shared/models/helicopter.model';
import { Operator } from 'src/app/shared/models/operator.model';
import { HelicopterAttributesService } from '../helicopter-attributes.service';
import { HelicopterImage, HelicopterService } from '../helicopter.service';
import { UpdateHelicopterOperatorDialogComponent } from '../../helicopter-operator/update-helicopter-operator-dialog/update-helicopter-operator-dialog.component';
import { HelicopterOperatorService } from 'src/app/helicopter-operator/helicopter-operator.service';
import { SharedService } from 'src/app/shared/shared.service';
import { forkJoin, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-helicopter',
  templateUrl: './update-helicopter.component.html',
  styleUrls: ['./update-helicopter.component.css']
})
export class UpdateHelicopterComponent implements OnInit {

  @ViewChild('heliForm', { static: false }) heliForm: NgForm;
  @ViewChildren('fligtOperationCheckbox') private fligtOperationCheckbox: QueryList<MatCheckbox>;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @ViewChild('fileInputPDF', { static: false }) fileInputPDF: ElementRef;

  private _onImagesChange: Subject<void> = new Subject();

  public heliFormGroup: FormGroup;
  public createState = true;
  public helicopterAttributes: HelicopterAttributes = new HelicopterAttributes();
  public operators: Operator[] = [];
  public images: HelicopterImage[] = [];
  public environment = environment;

  public imagePath: string | ArrayBuffer;
  public imageToUpload: File = null;
  public wrongFileType = false;
  public useImgForTablet = false;
  public showImageForTabletCheckbox = true;
  public cantDeleteTabletImage = false;

  public pdfsPath: string | ArrayBuffer[] = [];
  public pdfsToUpload: File[] = [];
  public pdfs: any[] = [];
  public fileExistsError = false;
  public pdfFileTypeError = false;

  public updateOperatorEnabled = false;
  public hasSecondEngine = false;

  private _userSessionObject;
  private _helicopterId: number;
  private _from = '';

  constructor(private _formBuilder: FormBuilder, private _localStorageService: LocalStorageService,
    private _helicopterService: HelicopterService, private _snackBar: MatSnackBar,
    private _translate: TranslateService, private _activatedRoute: ActivatedRoute, private _router: Router,
    private _dialog: MatDialog, private _helicopterAttributesService: HelicopterAttributesService,
    private _helicopterOperatorService: HelicopterOperatorService, private _sharedService: SharedService) {

    this._userSessionObject = this._localStorageService.getUserLocalStorageObject();
    this.heliFormGroup = this._formBuilder.group({
      id: [''],
      registrationMark: ['', Validators.required],
      serialNumber: ['', Validators.required],
      manufacturerDate: ['', [
        Validators.required,
        Validators.pattern(/^(1[0-2]|0[1-9]|[1-9])\/(20\d{2}|19\d{2}|0(?!0)\d|[1-9]\d)$/)
      ]],
      flightOperations: [[]],
      helicopterTypeId: ['', Validators.required],
      operatorId: [''],
      engineOilTypeId: ['', Validators.required],
      mgbOilTypeId: ['', Validators.required],
      tgbOilTypeId: ['', Validators.required],
      hydraulicFluidTypeId: ['', Validators.required],
      fuelTypeId: ['', Validators.required],
      airframeHours: ['0:00', [Validators.required, Validators.pattern(/^((0|[1-9][0-9]*):[0-5]\d$)$/g)]],
      airframeCycles: [0, Validators.required],
      hasEngine1: [{ value: true, disabled: true }, Validators.required],
      engine1Hours: ['0:00', [Validators.required, Validators.pattern(/^((0|[1-9][0-9]*):[0-5]\d$)$/g)]],
      engine1N1: ['00,00', Validators.min(0)],
      engine1N2: ['00,00', Validators.min(0)],
      engine1IMP: ['00,00', Validators.min(0)],
      hasEngine2: [false],
      engine2Hours: [{ value: '0:00', disabled: !this.hasSecondEngine }],
      engine2N1: [{ value: '00,00', disabled: !this.hasSecondEngine }],
      engine2N2: [{ value: '00,00', disabled: !this.hasSecondEngine }],
      engine2IMP: [{ value: '00,00', disabled: !this.hasSecondEngine }],
      organizationId: [this._userSessionObject.organizationId, Validators.required],
      emptyWeight: ['0.00', [Validators.required, Validators.pattern(/^(\d*\.?\d{1,2})$/)]],
      blocks: [''],
      documentNumber: ['']
    });
    this._helicopterAttributesService.getHelicopterAttributes(this._userSessionObject.organizationId).subscribe(result => {
      this.helicopterAttributes = result;
    });
  }

  ngOnInit(): void {
    const urlParams = this._activatedRoute.snapshot.params;
    if (this._activatedRoute.snapshot.url[2]) {
      this._from = this._activatedRoute.snapshot.url[2].path;
      if ((this._from !== 'fromlist')) { this._from = ''; }
    } else { this._from = ''; }

    if (urlParams.heliId && this._activatedRoute.snapshot.url[1].path === 'update') {
      this.createState = false;
      this._helicopterId = urlParams.heliId;
      this._helicopterService.getHelicopterById(urlParams.heliId).subscribe(result => {
        // console.log(result);
        this.heliFormGroup.patchValue(result);
        if (result.airframeHours) {
          this.heliFormGroup.controls['airframeHours'].setValue(result.airframeHours);
        }
        if (result.engine1Hours) {
          this.heliFormGroup.controls['engine1Hours'].setValue(result.engine1Hours);
        }

        if (!result.isEdit) {
          this._disableHoursInput();
        }
        // const flightOperationsArray = result.flightOperations.split(',');
        // this.heliFormGroup.patchValue({ flightOperations: flightOperationsArray });
        // this.fligtOperationCheckbox.toArray().map(item => {
        //   if (flightOperationsArray.includes(item.value.toString())) {
        //     item.checked = true;
        //   }
        // });
      }, error => {
        console.log(error);
        this._router.navigate(['/dashboard']);
        return;
      });
      this._getHelicopterImages();
      this._getHelicopterFiles();
    }

    this._helicopterOperatorService.getHelicopterOperators(this._userSessionObject.organizationId).subscribe(result => {
      this.operators = result;
    });

    this._helicopterOperatorService.helicopterOpreatorsUpdated.subscribe(operators => {
      this.operators = [];
      if (operators.length > 0) {
        this.operators = operators;
      }
    });

    this._helicopterOperatorService.helicopterOpreatorsInserted.subscribe(operator => {
      if (operator) {
        this.heliFormGroup.get('operatorId').setValue(operator.id);
      }
    });

    this.heliFormGroup.get('operatorId').valueChanges.subscribe(id => {
      this.updateOperatorEnabled = (id !== '' ? true : false);
    });

    this.heliFormGroup.get('hasEngine2').valueChanges.subscribe(value => {
      this.hasSecondEngine = value;
      if (value) {
        this.heliFormGroup.get('engine2Hours').enable();
        this.heliFormGroup.get('engine2Hours').setValidators([Validators.required, Validators.pattern(/^((0|[1-9][0-9]*):[0-5]\d$)$/g)]);
        this.heliFormGroup.get('engine2N1').enable();
        this.heliFormGroup.get('engine2N1').setValidators(Validators.min(0));
        this.heliFormGroup.get('engine2N2').enable();
        this.heliFormGroup.get('engine2N2').setValidators(Validators.min(0));
        this.heliFormGroup.get('engine2IMP').enable();
        this.heliFormGroup.get('engine2IMP').setValidators(Validators.min(0));
      } else {
        this.heliFormGroup.get('engine2Hours').disable();
        this.heliFormGroup.get('engine2Hours').clearValidators();
        this.heliFormGroup.get('engine2N1').disable();
        this.heliFormGroup.get('engine2N1').clearValidators();
        this.heliFormGroup.get('engine2N2').disable();
        this.heliFormGroup.get('engine2N2').clearValidators();
        this.heliFormGroup.get('engine2IMP').disable();
        this.heliFormGroup.get('engine2IMP').clearValidators();
      }
    });
    this._helicopterService.onHelicopterImagesChange.subscribe(() => {
      this._getHelicopterImages();
    });
  }

  private _disableHoursInput() {
    this.heliFormGroup.controls['hasEngine1'].disable();
    this.heliFormGroup.controls['engine1Hours'].disable();
    this.heliFormGroup.controls['engine1N1'].disable();
    this.heliFormGroup.controls['engine1N2'].disable();
    this.heliFormGroup.controls['engine1IMP'].disable();
    this.heliFormGroup.controls['hasEngine2'].disable();
    this.heliFormGroup.controls['engine2Hours'].disable();
    this.heliFormGroup.controls['engine2N1'].disable();
    this.heliFormGroup.controls['engine2N2'].disable();
    this.heliFormGroup.controls['engine2IMP'].disable();
    this.heliFormGroup.controls['airframeHours'].disable();
    this.heliFormGroup.controls['airframeCycles'].disable();
    // this.heliFormGroup.controls['emptyWeight'].disable();
  }

  private _getHelicopterImages() {
    if (this._helicopterId) {
      this._helicopterService.getHelicoptersImages(this._helicopterId).subscribe(images => {
        this.images = images;
        this.images.map(i => {
          if (i.type === 1) {
            this.showImageForTabletCheckbox = false;
            return;
          }
        });
      });
    }
  }

  private _getHelicopterFiles() {
    if (this._helicopterId) {
      this._helicopterService.getHelicoptersFiles(this._helicopterId).subscribe(files => {
        this.pdfs = files;
      });
    }
  }

  openUpdateOperatorDialog(newOperatorAction: boolean) {
    this._dialog.open(UpdateHelicopterOperatorDialogComponent, {
      disableClose: true,
      width: '50%',
      data: {
        operatorId: (newOperatorAction ? null : this.heliFormGroup.get('operatorId').value),
        organizationId: this._userSessionObject.organizationId
      }
    });
  }

  onHeliFormSubmit() {
    this.cantDeleteTabletImage = false;
    this.heliFormGroup.get('flightOperations').setErrors(null);
    if (this.heliForm.valid) {
      const heliObject = { ... this.heliFormGroup.getRawValue() } as Helicopter;
      // tslint:disable-next-line:radix
      heliObject.engine1N1 = this._twoDecimal(parseInt(heliObject.engine1N1));
      // tslint:disable-next-line:radix
      heliObject.engine1N2 = this._twoDecimal(parseInt(heliObject.engine1N2));
      // tslint:disable-next-line:radix
      heliObject.engine1IMP = this._twoDecimal(parseInt(heliObject.engine1IMP));
      // tslint:disable-next-line:radix
      heliObject.engine2N1 = this._twoDecimal(parseInt(heliObject.engine2N1));
      // tslint:disable-next-line:radix
      heliObject.engine2N2 = this._twoDecimal(parseInt(heliObject.engine2N2));
      // tslint:disable-next-line:radix
      heliObject.engine2IMP = this._twoDecimal(parseInt(heliObject.engine2IMP));
      this._helicopterService.updateHelicopter(heliObject, this.createState).subscribe(result => {
      const promisespdf = [];

      this.pdfsToUpload.forEach(pdf => promisespdf.push(this._helicopterService.uploadHelicopterFile(pdf, result.id.toString())));

        // tslint:disable-next-line:max-line-length
      forkJoin([
        ...promisespdf,
        this._helicopterService.uploadHelicopterImage(this.imageToUpload, result.id.toString(), this.useImgForTablet)
      ]).subscribe((res) => {
        this._showSuccessSnackBar();
        if (this._from === 'fromlist') { this._router.navigate(['/helicopters/list']); }
      });

        if (this.createState) {
          this.heliForm.resetForm({
            organizationId: this._userSessionObject.organizationId,
            hasEngine1: true,
            engine1Hours: '0:00',
            engine1N1: '00,00',
            engine1N2: '00,00',
            engine1IMP: '00,00',
            hasEngine2: false,
            engine2Hours: '0:00',
            engine2N1: '00,00',
            engine2N2: '00,00',
            engine2IMP: '00,00',
            airframeHours: '0:00',
            airframeCycles: 0,
            flightOperations: [],
          });

          this.fligtOperationCheckbox.toArray().map(item => item.checked = false);
          this.images = [];
          this._removeChoosenImage();
          // this._helicopterId = result.id;
          this._helicopterId = null;
        }
      });
    } else {
      if (this.heliFormGroup.get('flightOperations').value.length === 0) {
        this.heliFormGroup.get('flightOperations').setErrors({ missing: true });
      }
    }
  }

  private _showSuccessSnackBar() {
    this._removeChoosenImage();
    this._snackBar.open(this._translate.instant('GENERAL.savedSuccessMsg'), this._translate.instant('GENERAL.close'), {
      duration: 3000,
    });
    // this._router.navigate(['/dashboard']);
  }

  handleImageFileInput(files: FileList) {
    this.wrongFileType = false;
    if (files.length === 0) { return; }
    if (files.length > 1) { return; }
    const mimeType = files[0].type;
    if (mimeType.match(/[\/.]gif|jpe?g|tiff?|png|webp|bmp$/i) == null) {
      this.wrongFileType = true;
      return;
    }
    const reader = new FileReader();
    this.imageToUpload = files.item(0);
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imagePath = reader.result;
    };
  }

  private _removeChoosenImage() {
    this.imageToUpload = null;
    this.imagePath = '';
    this.fileInput.nativeElement.value = '';
  }

  handlePDFFileInput(files: FileList) {
    if (files.length === 0) { return; }

    this.fileExistsError = false;
    this.pdfFileTypeError = false;
    const filesToAdd = [];

    for (let i = 0; i < files.length; i++) {
      if (files.item(i).type.match(/[\/.]pdf$/i) != null) {
        this.pdfs.forEach(pdf => {
          if (pdf.name === files.item(i).name.replace(/\.[^/.]+$/, '')) {
            this.fileExistsError = true;
            return;
          }
        });
        if (this.fileExistsError) {
          return;
        }
        filesToAdd.push(files.item(i));
      } else {
        this.pdfFileTypeError = true;
        return;
      }
    }
    this.pdfsToUpload.push(...filesToAdd);
  }

  deleteImage(imageId: number) {
    this.cantDeleteTabletImage = false;
    const image = this.images.filter(i => i.id === imageId)[0];
    if (image.type === 1) {
      this.cantDeleteTabletImage = true;
      return;
    }
    this._helicopterService.deleteHelicopterImage(imageId).subscribe();
  }

  deletePDF(index: number) {
    this.pdfsToUpload.splice(index, 1);
  }

  deleteUploadedPDF(pdfId: number) {
    this._helicopterService.deleteHelicopterFiles(pdfId).subscribe(res => {
      this.pdfs = this.pdfs.filter(pdf => pdf.id !== pdfId);
    });
  }

  setImageForTablet(imageId: number) {
    this.cantDeleteTabletImage = false;
    this._helicopterService.setMassBalanceImage(imageId, this._helicopterId).subscribe(result => {
      this.images = result;
    });
  }

  private _twoDecimal(yourNumber: number) {
    return parseFloat((Math.round((!yourNumber ? 0 : yourNumber) * 100) / 100).toString()).toFixed(2);
  }


}
