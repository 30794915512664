import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { ProfileService } from '../profile.service';

export class PasswordValidator {
  static checkPasswordMatch(group: FormGroup): ValidationErrors | null {
    const pass = group.get('newPassword').value;
    const confirmPass = group.get('repeatPassword').value;
    if (pass !== confirmPass) {
      group.get('repeatPassword').setErrors({ notSame: true });
      return { notSame: true };
    }
    return null;
  }
}

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {

  @ViewChild('passwordForm', { static: false }) passwordForm: NgForm;
  public profileFormGroup: FormGroup;
  public passwordFormGroup: FormGroup;
  public userSession;

  constructor(private _formBuilder: FormBuilder, private _profileService: ProfileService,
    private _localStorageService: LocalStorageService, private _snackBar: MatSnackBar,
    private _translate: TranslateService) {
    this.userSession = this._localStorageService.getUserLocalStorageObject();
    const userNameArray = this.userSession.userName.split(' ');

    this.profileFormGroup = this._formBuilder.group({
      firstName: [userNameArray[0], Validators.required],
      lastName: [userNameArray[1], Validators.required]
    });

    this.passwordFormGroup = this._formBuilder.group({
      password: ['', Validators.required],
      newPassword: ['', Validators.required],
      repeatPassword: ['', Validators.required]
    }, {
      validators: [PasswordValidator.checkPasswordMatch]
    });
  }

  ngOnInit(): void {
  }

  onProfileFormSubmit() {
    if (this.profileFormGroup.valid) {
      this._profileService.updateProfileData(this.profileFormGroup.value.firstName, this.profileFormGroup.value.lastName)
        .subscribe(() => {
          const userName = this.profileFormGroup.value.firstName + ' ' + this.profileFormGroup.value.lastName;
          this._localStorageService.setUserLocalStorage('userName', userName);
          this._snackBar.open(this._translate.instant('GENERAL.savedSuccessMsg'), this._translate.instant('GENERAL.close'), {
            duration: 3000,
          });
        });
    }
  }

  onPasswordFormSubmit() {
    if (this.passwordFormGroup.valid) {
      this._profileService.updateProfilePassword(this.passwordFormGroup.value.password, this.passwordFormGroup.value.newPassword)
        .subscribe(
        result => {
          // this.passwordFormGroup.reset({ passwrd: '', newPassword: '', repeatPassword: '' });
          this.passwordForm.resetForm();
          this._snackBar.open(this._translate.instant('GENERAL.savedSuccessMsg'), this._translate.instant('GENERAL.close'), {
            duration: 3000,
          });
        },
        error => {
          this._snackBar.open(this._translate.instant('PROFILE.UPDATE.wrongPasswordError'), this._translate.instant('GENERAL.close'), {
            duration: 3000,
          });
        });
    }
  }

}
