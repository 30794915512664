import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HelicopterAttributes } from '../shared/models/helicopter-attribute.model';


enum EndpointRoutes {
  GET_HELICOPTER_ATTRIBUTES = 'helicopter/attributes/get'
}

@Injectable({
  providedIn: 'root'
})
export class HelicopterAttributesService {

  constructor(private _http: HttpClient) { }

  public helicopterAttributes: HelicopterAttributes;

  public getHelicopterAttributes(orgId: string) {
    let params = new HttpParams();
    params = params.append('organizationId', orgId);
    return this._http.get<HelicopterAttributes>(environment.serverUrl + EndpointRoutes.GET_HELICOPTER_ATTRIBUTES, {
      params: params
    }).pipe(
      map(responseData => {
        this.helicopterAttributes = responseData;
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

}
