import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { LoginService } from './login/login.service';
import { LocalStorageService } from './shared/local-storage.service';
import { SharedService } from './shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'helilog-frontend';

  constructor(public translate: TranslateService, private _localStorageService: LocalStorageService,
    private _loginService: LoginService, private _sharedService: SharedService) {
    translate.addLangs(['it', 'de', 'en']);
    const browserLang = translate.getBrowserLang();
    if (this._localStorageService.appLocalStorageExist()) {
      const sessionObject = this._localStorageService.getAppLocalStorageObject();
      translate.use(sessionObject.lang);
      translate.setDefaultLang(sessionObject.lang);
    } else {
      const lang = browserLang.match(/en|it/) ? browserLang : 'en';
      translate.use(lang);
      this._localStorageService.setAppLocalStorage('lang', lang);
      translate.setDefaultLang(lang);
    }
  }
  ngOnInit(): void {
    this._loginService.autoLogin();
  }

}
