import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { LoginService } from '../login/login.service';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-top-toolbar',
  templateUrl: './top-toolbar.component.html',
  styleUrls: ['./top-toolbar.component.css']
})
export class TopToolbarComponent implements OnInit {

  @Input() sidenav: MatSidenav;
  @Input() loginComponent: boolean;
  constructor(public translateService: TranslateService, private _sharedService: SharedService, private _loginService: LoginService,
    private router: Router) { }

  ngOnInit(): void {
  }

  changeLanguageClick(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem(environment.appSessionUniqueId, JSON.stringify({ 'lang': lang }));
  }

  doUserLogout() {
    this._loginService.logout();
  }

  emitSidenavToggle() {
    this._sharedService.sidenavToggle.emit();
  }

  viewProfile() {
    this.router.navigate(['/profile']);
  }


}
