import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Operator } from '../shared/models/operator.model';

enum EndpointRoutes {
  GET_HELICOPTER_OPERATOR = 'operator/get',
  UPDATE_HELICOPTER_OPERATOR = 'operator/update',
  INSERT_HELICOPTER_OPERATOR = 'operator/insert',
}

@Injectable({
  providedIn: 'root'
})
export class HelicopterOperatorService {

  private _helicopterOpreators: Operator[] = [];
  public helicopterOpreatorsInserted = new BehaviorSubject<Operator>(null);
  public helicopterOpreatorsUpdated = new BehaviorSubject<Operator[]>([]);

  constructor(private _http: HttpClient) { }

  private _updateHelicopterOpreatorsObject(newObjcet: Operator): Operator[] {
    const updateItem = this._helicopterOpreators.find(operator => operator.id === newObjcet.id);
    const index = this._helicopterOpreators.indexOf(updateItem);
    this._helicopterOpreators[index] = newObjcet;
    return this._helicopterOpreators;
  }

  getHelicopterOperator(operatorId: number): Operator {
    return { ... this._helicopterOpreators.filter(operator => operator.id === operatorId)[0] };
  }

  getHelicopterOperators(orgId: string): Observable<Operator[]> {
    let params = new HttpParams();
    params = params.append('organizationId', orgId);
    return this._http.get<Operator[]>(environment.serverUrl + EndpointRoutes.GET_HELICOPTER_OPERATOR, {
      params: params
    }).pipe(
      map(responseData => {
        this._helicopterOpreators = responseData;
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  updateHelicopterOperator(operatorObject: Operator): Observable<Operator> {
    return this._http.post<Operator>(environment.serverUrl + EndpointRoutes.UPDATE_HELICOPTER_OPERATOR, { operator: operatorObject }).pipe(
      map(responseData => {
        const result = this._updateHelicopterOpreatorsObject(responseData);
        this.helicopterOpreatorsUpdated.next(result);
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  insertHelicopterOperator(operatorObject: Operator): Observable<Operator> {
    return this._http.put<Operator>(environment.serverUrl + EndpointRoutes.INSERT_HELICOPTER_OPERATOR, { operator: operatorObject }).pipe(
      map(responseData => {
        this._helicopterOpreators.push(responseData);
        this.helicopterOpreatorsInserted.next(responseData);
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }
}
