import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login/login.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private _loginService: LoginService, private _router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 410 || err.status === 400 || err.status === 409 || err.status === 0) {
                this._loginService.logout();
                this._router.navigate(['/login']);
            }
            if (!environment.production) {
                console.log(err);
            }
            // const error = err.status;
            return throwError(err);
        }));
    }
}
