import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class Organization {
  id: number;
  name: string;
  email: string;
  phone: string;
  fax: string;
  street: string;
  city: string;
  postalCode: string;
  notes: string;
  status: number;
  countryId: number;
  organizationInvoiceDetails: InvoiceDetails;
}

export class InvoiceDetails {
  id: number;
  vat: string;
  pecEmail: string;
  isdNumber: string;
  street: string;
  city: string;
  postalCode: string;
  countryId: number;
}


enum EndpointRoutes {
  UPDATE_ORGANIZATION = 'organization/update',
  GET_ORGANIZATION = 'organization/get',
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private _http: HttpClient) { }

  public getOrganizationById(orgId: string) {
    let params = new HttpParams();
    params = params.append('id', orgId);
    return this._http.get<Organization>(environment.serverUrl + EndpointRoutes.GET_ORGANIZATION, {
      params: params
    }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public updateOrganization(orgObject: Organization) {
    return this._http.post<Organization>(environment.serverUrl + EndpointRoutes.UPDATE_ORGANIZATION, { 'organization': orgObject }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }
}
