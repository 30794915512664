import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { PlainPasswordDialogComponent } from 'src/app/shared/plain-password-dialog/plain-password-dialog.component';
import { MaintenanceAccount, MaintenanceService } from '../maintenance.service';

@Component({
  selector: 'app-maintenance-list',
  templateUrl: './maintenance-list.component.html',
  styleUrls: ['./maintenance-list.component.css']
})
export class MaintenanceListComponent implements OnInit {

  public maintenanceAccountArray: MaintenanceAccount[] = [];

  private _userSession: any;

  constructor(private _maintenanceService: MaintenanceService, private _localStorageService: LocalStorageService,
    private _dialog: MatDialog, private _translate: TranslateService, private _snackBar: MatSnackBar) {
    this._userSession = this._localStorageService.getUserLocalStorageObject();
  }

  ngOnInit(): void {
    this._maintenanceService.getMaintenanceAccounts(this._userSession.organizationId).subscribe(result => {
      this.maintenanceAccountArray = result;
    });

    this._maintenanceService.maintenanceAccountUpdated.subscribe(result => {
      this.maintenanceAccountArray = [];
      this.maintenanceAccountArray = result;
    });
  }

  openConfirmResetPasswordDialog(accountId: string) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: this._translate.instant('CONFIRMATIONDIALOG.resetPasswordQuestion')
    });
    dialogRef.afterClosed().subscribe(clickResult => {
      if (clickResult) {
        this._maintenanceService.resetMaintenanceAccountPassword(accountId).subscribe(newPassword => {
          this._dialog.open(PlainPasswordDialogComponent, {
            width: '350px',
            data: newPassword.password
          });
          this._snackBar.open(this._translate.instant('CONFIRMATIONDIALOG.passwordSuccessResetMsg'),
            this._translate.instant('GENERAL.close'), {
            duration: 3000,
          });
        });
      }
    });
  }

  openConfirmDeleteDialog(accountId: string, arrayIndex: number) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: this._translate.instant('CONFIRMATIONDIALOG.resetPasswordQuestion')
    });
    dialogRef.afterClosed().subscribe(clickResult => {
      if (clickResult) {
        this._maintenanceService.softDeleteMaintenanceAccount(accountId, arrayIndex).subscribe(() => {
          this._snackBar.open(this._translate.instant('GENERAL.deleteSuccessMsg'), this._translate.instant('GENERAL.close'), {
            duration: 3000,
          });
        }, error => {
          console.error(error);
        });
      }
    });
  }

}
