import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


export class Pilot {
  id: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string; // yyyy-mm-dd
  placeOfBirth: string;
  street: string;
  city: string;
  postalCode: string;
  countryId: string;
  organizationId: number;
  idCardNumber: number;
  idCardExpiryDate: string;
  licenseNumber: string;
  issueLicenseDate: string;
  visitDate: string;
  expiryVisitDate: string;
  drivingLicense: string;
  expiryDrivingLicenseDate: string;
  caPart: string;
  pilotRoleId: number;
  username: string;
  signaturePath: string;
  password: string;
}

enum EndpointRoutes {
  INSERT_PILOT = 'pilot/insert',
  UPDATE_PILOT = 'pilot/update',
  GET_PILOT = 'pilot/get',
  DELETE_PILOT = 'pilot/delete',
  UPLOAD_SIGNATURE = 'pilot/saveImage',
  RESET_PASSWORD = 'pilot/resetPassword'
}

@Injectable({
  providedIn: 'root'
})
export class PilotService {

  private _pilots: Pilot[] = [];
  public pilots: Subject<Pilot[]> = new Subject();

  private _getPilots() {
    return this._pilots.slice();
  }

  constructor(private _http: HttpClient) { }

  public getPilotById(pilotId: string) {
    let params = new HttpParams();
    params = params.append('id', pilotId);
    return this._http.get<Pilot>(environment.serverUrl + EndpointRoutes.GET_PILOT, {
      params: params
    }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public updatePilot(pilotObject: Pilot, createPilot: boolean): Observable<Pilot> {
    if (createPilot) {
      return this._http.put<Pilot>(environment.serverUrl + EndpointRoutes.INSERT_PILOT, pilotObject).pipe(
        map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
    } else {
      return this._http.post<Pilot>(environment.serverUrl + EndpointRoutes.UPDATE_PILOT, pilotObject).pipe(
        map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
    }
  }

  public getPilotesByOrgId(orgId: string) {
    let params = new HttpParams();
    params = params.append('organizationId', orgId);
    return this._http.get<Pilot[]>(environment.serverUrl + EndpointRoutes.GET_PILOT, { params: params }).pipe(
      map(responseData => {
        this._pilots = responseData;
        this.pilots.next(responseData);
        return responseData;
      }),
      catchError(errorRes => {
        console.log(errorRes);
        return throwError(errorRes);
      })
    );
  }

  public softDeletePilotById(pilotId: string, arrayIndex: number) {
    let params = new HttpParams();
    params = params.append('id', pilotId);
    return this._http.delete<any>(environment.serverUrl + EndpointRoutes.DELETE_PILOT, { params: params }).pipe(
      map(responseData => {
        this._pilots.splice(arrayIndex, 1);
        this.pilots.next(this._getPilots());
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public uploadPilotSignatureImage(image: File, pilotId: string, orgId: string) {
    const formData = new FormData();
    formData.append('organizationId', orgId);
    formData.append('pilotId', pilotId);
    formData.append('type', 'signature');
    formData.append('image', image);
    return this._http.post<any>(environment.serverUrl + EndpointRoutes.UPLOAD_SIGNATURE, formData).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public resetPilotPassword(pilotId: string) {
    return this._http.put<any>(environment.serverUrl + EndpointRoutes.RESET_PASSWORD, { id: pilotId }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }
}
