import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalStorageService, UserLocalStorageObject } from '../shared/local-storage.service';

class LoginReturnData {
  sessionToken: string;
  organizationId: number;
  user: {
    username: string;
    firstName: string;
    lastName: string;
  };
}

export class LoginReturnError {
  stringCode: string;
  value: string | { waitTime: string };
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  userData = new BehaviorSubject<UserLocalStorageObject>(null);

  constructor(private _http: HttpClient, private _router: Router, private _localStorageService: LocalStorageService) { }

  loginPost(username: string, password: string) {
    return this._http.post<LoginReturnData>(environment.serverUrl + 'account/login', { username, password })
      .pipe(delay(500), catchError(this._handleError),
        tap((resData: LoginReturnData) => {
          const userName = resData.user.firstName + ' ' + resData.user.lastName;
          const userData = new UserLocalStorageObject(resData.user.username, resData.sessionToken, userName, resData.organizationId);
          localStorage.setItem(environment.userSessionUniqueId, JSON.stringify(userData));
          this.userData.next(userData);
        }));
  }


  public get currentUserDataValue(): UserLocalStorageObject {
    return this.userData.value;
  }

  autoLogin() {
    const userData: UserLocalStorageObject = JSON.parse(localStorage.getItem(environment.userSessionUniqueId));
    if (!userData) {
      return;
    }
    const loadedUser = new UserLocalStorageObject(
      userData.email,
      userData.sessionToken,
      userData.userName,
      userData.organizationId
    );
    if (loadedUser.sessionToken) {
      this.userData.next(loadedUser);
    }
  }

  logout() {
    this.userData.next(null);
    localStorage.removeItem(environment.userSessionUniqueId);
    this._router.navigate(['/login']);
  }

  private _handleError(reponseError: HttpErrorResponse): Observable<LoginReturnError> {
    console.log(reponseError);
    let errorCode: LoginReturnError;
    switch (reponseError.status) {
      case 604:
        errorCode = {stringCode: 'accountDisabledError', value: reponseError.error};
        break;
      case 662:
        errorCode = {stringCode: 'multipleFailedLoginError', value: reponseError.error};
        break;
      case 663:
        errorCode = {stringCode: 'accountBlockedError', value: reponseError.error};
        break;
      default:
        errorCode = {stringCode: 'wrongCredentialsError', value: reponseError.error};
        break;
    }

    return throwError(errorCode);
  }
}
