import { Operator } from './operator.model';

export class HelicopterAttributes {
    helicopterManufacturers: HelicopterManufacturer[];
    helicopterTypes: HelicopterType[];
    operators: Operator[];
    oilManufacturers: [{ id: number, name: string }];
    oilTypes: OilType[];
    hydraulicFluidTypeManufacturers: [{ id: number, name: string }];
    hydraulicFluidTypes: HydraulicFluidType[];
    fuelTypes: FuelType[];
    flightOperations: FlightOperation[];
}


export class HelicopterManufacturer {
    id: number;
    name: string;
    organizationId: number;
}

export class HelicopterType {
    id: number;
    name: string;
    helicopterManufacturerId: number;
}

export class OilType {
    id: number;
    name: string;
    organizationId: number;
    oilTypeManufacturerId: number;
}

export class HydraulicFluidType {
    id: number;
    name: string;
    organizationId: number;
    hydraulicFluidTypeManufacturerId: number;
}

export class FuelType {
    id: number;
    name: string;
    organizationId: number;
}

export class FlightOperation {
    id: number;
    name: string;
    description: string;
}
