import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { Country, SharedService } from 'src/app/shared/shared.service';
import { Organization, OrganizationService } from '../organization.service';

@Component({
  selector: 'app-update-organization',
  templateUrl: './update-organization.component.html',
  styleUrls: ['./update-organization.component.css']
})
export class UpdateOrganizationComponent implements OnInit {

  @ViewChild('tabGroup', { static: false }) tabGroup: MatTabGroup;
  public orgFormGroup: FormGroup;
  public countries: Country[] = [];

  constructor(private _formBuilder: FormBuilder, private _localStorageService: LocalStorageService,
    private _organizationService: OrganizationService, private _acivatedRoute: ActivatedRoute, private _router: Router,
    private _translate: TranslateService, private _snackBar: MatSnackBar, private _sharedService: SharedService) {
    const appSession = this._localStorageService.getAppLocalStorageObject();
    this.countries = appSession.countries;

    this.orgFormGroup = this._formBuilder.group({
      id: [''],
      name: [''],
      email: [''],
      phone: [''],
      fax: [''],
      street: [''],
      city: [''],
      postalCode: [''],
      countryId: [1],
      notes: [''],
      organizationInvoiceDetails: this._formBuilder.group({
        id: [''],
        vat: [''],
        pecEmail: [''],
        isdNumber: [''],
        street: [''],
        city: [''],
        postalCode: [''],
        countryId: [1],
      })
    });
  }

  ngOnInit(): void {
    const userSession = this._localStorageService.getUserLocalStorageObject();
    const getParams = this._acivatedRoute.snapshot.params;
    if (getParams.orgId && (getParams.orgId === userSession.organizationId.toString()) && this._acivatedRoute.snapshot.url[1].path === 'update') {
      this._organizationService.getOrganizationById(getParams.orgId).subscribe(result => {
        this.orgFormGroup.patchValue(result);
      }, () => {
        this._router.navigate(['/dashboard']);
      });
    }
  }

  onOrgFormSubmit() {
    if (this.orgFormGroup.valid) {
      const orgObject = this.orgFormGroup.value as Organization;
      this._organizationService.updateOrganization(orgObject).subscribe(() => {
        this.tabGroup.selectedIndex = 0;
        this._snackBar.open(this._translate.instant('GENERAL.savedSuccessMsg'), this._translate.instant('GENERAL.close'), {
          duration: 3000,
        });
      });
    } else {
      const invoiceFormGroup: FormGroup = this.orgFormGroup.get('organizationInvoiceDetails') as FormGroup;
      if (this._sharedService.checkIfFormGroupHasError(this.orgFormGroup)) {
        this.tabGroup.selectedIndex = 0;
        return;
      }
      if (this._sharedService.checkIfFormGroupHasError(invoiceFormGroup)) {
        this.tabGroup.selectedIndex = 1;
        return;
      }
    }
  }


}
