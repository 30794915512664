import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { Operator } from 'src/app/shared/models/operator.model';
import { Country } from 'src/app/shared/shared.service';
import { HelicopterOperatorService } from '../helicopter-operator.service';

@Component({
  selector: 'app-update-helicopter-operator-dialog',
  templateUrl: './update-helicopter-operator-dialog.component.html',
  styleUrls: ['./update-helicopter-operator-dialog.component.css']
})
export class UpdateHelicopterOperatorDialogComponent {

  @ViewChild('operatorForm', { static: false }) operatorForm: NgForm;
  operatorFormGroup: FormGroup;
  public countries: Country[];

  constructor(
    public dialogRef: MatDialogRef<UpdateHelicopterOperatorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _operatorObject: { operatorId: number, organizationId: number }, private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar, private _translate: TranslateService, private _helicopterOperatorService: HelicopterOperatorService,
    private _localStorageService: LocalStorageService) {

    const appSessionObject = this._localStorageService.getAppLocalStorageObject();
    this.operatorFormGroup = this._formBuilder.group({
      id: [''],
      name: ['', <any>Validators.required],
      registeredStreet: [''],
      registeredCAP: [''],
      registeredCity: [''],
      registeredProivince: [''],
      registeredCountry: [1],
      operationalStreet: [''],
      operationalCAP: [''],
      operationalCity: [''],
      operationalProivince: [''],
      operationalCountry: [1],
      vat: [''],
      sdiCode: [''],
      pec: ['', <any>[Validators.email]],
      adminEmail: ['', [Validators.required, Validators.email]],
      commercialEmail: ['', <any>[Validators.email]],
      phone: [''],
      aocNumber: [''],
      organizationId: [this._operatorObject.organizationId]
    });
    this.countries = appSessionObject.countries;
    if (this._operatorObject.operatorId) {
      this.operatorFormGroup.patchValue(this._helicopterOperatorService.getHelicopterOperator(this._operatorObject.operatorId));
    }
  }

  private streetEmpty: boolean;
  private cityEmpty: boolean;
  private postalEmpty: boolean;
  private provinceEmpty: boolean;

  onFormSubmit() {
    if (this.operatorFormGroup.valid) {
      const object = { ...this.operatorFormGroup.value } as Operator;
      let observ: Observable<Operator>;
      if (this._operatorObject.operatorId) {
        observ = this._helicopterOperatorService.updateHelicopterOperator(object);
      } else {
        observ = this._helicopterOperatorService.insertHelicopterOperator(object);
      }
      observ.subscribe(result => {
        this.dialogRef.close();
        this._snackBar.open(this._translate.instant('GENERAL.savedSuccessMsg'), this._translate.instant('GENERAL.close'), {
          duration: 3000,
        });
      });
    }
  }

  onInputCopyValue(event, field: 'street' | 'city' | 'postal' | 'province') {
    switch (field) {
      case 'street':
        if (this.streetEmpty) {
          this.operatorFormGroup.controls['operationalStreet'].setValue(event.target.value);
        }
        break;
      case 'city':
        if (this.cityEmpty) {
          this.operatorFormGroup.controls['operationalCity'].setValue(event.target.value);
        }
        break;
      case 'postal':
        if (this.postalEmpty) {
          this.operatorFormGroup.controls['operationalCAP'].setValue(event.target.value);
        }
        break;
      case 'province':
        if (this.provinceEmpty) {
          this.operatorFormGroup.controls['operationalProivince'].setValue(event.target.value);
        }
        break;
    }
  }

  onInputFocus(field: 'street' | 'city' | 'postal' | 'province') {
    switch (field) {
      case 'street':
        if (this.operatorFormGroup.controls['operationalStreet'].value !== '') {
          this.streetEmpty = false;
        } else {
          this.streetEmpty = true;
        }
        break;
      case 'city':
        if (this.operatorFormGroup.controls['operationalCity'].value !== '') {
          this.cityEmpty = false;
        } else {
          this.cityEmpty = true;
        }
        break;
      case 'postal':
        if (this.operatorFormGroup.controls['operationalCAP'].value !== '') {
          this.postalEmpty = false;
        } else {
          this.postalEmpty = true;
        }
        break;
      case 'province':
        if (this.operatorFormGroup.controls['operationalProivince'].value !== '') {
          this.provinceEmpty = false;
        } else {
          this.provinceEmpty = true;
        }
        break;
    }
  }

}
