import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { LocalStorageService } from '../shared/local-storage.service';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(private _sharedService: SharedService, private _localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this._sharedService.sidenavToggle.subscribe(() => {
      this.sidenav.toggle();
    });
    this._localStorageService.userLocalStorageInserted.subscribe(result => {
      const sessionObject = this._localStorageService.getAppLocalStorageObject();
      if (!sessionObject.countries) {
        this._sharedService.getCountries().subscribe(countries => {
          this._localStorageService.setAppLocalStorage('countries', JSON.stringify(countries));
        });
      }
      if (!sessionObject.roles) {
        this._sharedService.getPilotRoles().subscribe(roles => {
          this._localStorageService.setAppLocalStorage('roles', JSON.stringify(roles));
        });
      }
    });
  }

}
