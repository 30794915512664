import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Pilot, PilotService } from '../pilot/pilot.service';
import { LocalStorageService } from '../shared/local-storage.service';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { HelicopterService } from '../helicopter/helicopter.service';
import { PlainPasswordDialogComponent } from '../shared/plain-password-dialog/plain-password-dialog.component';
import { Helicopter } from '../shared/models/helicopter.model';
import { Operator } from '../shared/models/operator.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name', 'serialNumber', 'modelType', 'registration', 'actions'];
  helicoptersDataSource: Helicopter[] = [];
  pilotDataSource: Pilot[] = [];

  private _helicopterTypes = [];
  private _operators = [];

  constructor(private _pilotService: PilotService, private _localStorageService: LocalStorageService, private _dialog: MatDialog,
    private _translate: TranslateService, private _snackBar: MatSnackBar, private _helicoptersService: HelicopterService) { }

  ngOnInit(): void {
    const userSessionObject = this._localStorageService.getUserLocalStorageObject();
    this._helicoptersService.getHelicopterAttributes(userSessionObject.organizationId).subscribe(attributes => {
      this._helicopterTypes = attributes.helicopterTypes;
      this._operators = attributes.operators;
      this._pilotService.getPilotesByOrgId(userSessionObject.organizationId.toString()).subscribe();
      this._helicoptersService.getHelicoptersByOrgId(userSessionObject.organizationId.toString()).subscribe();
      this._helicoptersService.helicopters.subscribe(result => {
        this.helicoptersDataSource = [];
        this.helicoptersDataSource = result;
      });
      this._pilotService.pilots.subscribe(result => {
        this.pilotDataSource = [];
        this.pilotDataSource = result;
      });
    });

  }

  openConfirmDeleteDialog(type: string, elmId: string, index: number): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: this._translate.instant('CONFIRMATIONDIALOG.deleteDataQestion')
    });
    dialogRef.afterClosed().subscribe(clickResult => {
      if (clickResult) {
        let observ: Observable<any>;
        if (type === 'pilot') {
          observ = this._pilotService.softDeletePilotById(elmId, index);
        } else {
          observ = this._helicoptersService.softDeleteHelicopterById(elmId, index);
        }
        observ.subscribe(result => {
          this._snackBar.open(this._translate.instant('GENERAL.deleteSuccessMsg'), this._translate.instant('GENERAL.close'), {
            duration: 3000,
          });
        });
      }
    });
  }

  openConfirmResetPasswordDialog(pilotId: string) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: this._translate.instant('CONFIRMATIONDIALOG.resetPasswordQuestion')
    });
    dialogRef.afterClosed().subscribe(clickResult => {
      if (clickResult) {
        this._pilotService.resetPilotPassword(pilotId).subscribe(() => {
          this._snackBar.open(this._translate.instant('CONFIRMATIONDIALOG.passwordSuccessResetMsg'),
            this._translate.instant('GENERAL.close'), {
            duration: 3000,
          });
        });
      }
    });
  }

  getHelicopterType(id: number) {
    const type = this._helicopterTypes.filter(t => t.id === id)[0];
    return (type ? type.name : '');
  }

  getOperatorName(id: number) {
    const operator = this._operators.filter(o => o.id === id)[0];
    return (operator ? operator.name : '');
  }

}
