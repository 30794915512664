import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'freelancerStatus'
})
export class FreelancerStatusPipe implements PipeTransform {

  constructor(private _translate: TranslateService) {}

  transform(value: number): string {
    return this._translate.instant('GENERAL.'+(value === 0 ? 'no' : 'yes'));
  }

}
