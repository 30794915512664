import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class MaintenanceAccount {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  placeOfBirth: string;
  phone: string;
  email: string;
  lmaNumber: string;
  lmaExpiryDate: string;
  freelancer: number;
  stampImage: string;
  signatureImage: string;
  maintenanceCompanyId: number;
  organizationId: number;
  maintenanceCompany?: MaintenanceCompany;
  helicopters: number[];
}

export class MaintenanceCompany {
  id: number;
  name: string;
  caPart145Number: string;
  caPart145ExpiryDate: string;
  organizationId: number;
}

enum EndpointRoutes {
  INSERT_MAINTENANCE_COMPANY = 'maintenanceCompany/insert',
  UPDATE_MAINTENANCE_COMPANY = 'maintenanceCompany/update',
  GET_MAINTENANCE_COMPANIES = 'maintenanceCompany/all',
  INSERT_MAINTENANCE_ACCOUNT = 'maintenanceAccount/insert',
  UPDATE_MAINTENANCE_ACCOUNT = 'maintenanceAccount/update',
  UPLOAD_IMAGE = 'maintenanceAccount/saveImage',
  GET_MAINTENANCE_ACCOUNT = 'maintenanceAccount/get',
  GET_MAINTENANCE_ACCOUNTS = 'maintenanceAccount/all',
  DELETE_MAINTENANCE_ACCOUNT = 'maintenanceAccount/delete',
  RESET_PASSWORD_MAINTENANCE_ACCOUNT = 'maintenanceAccount/resetPassword'
}

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  private _maintenanceCompanies: MaintenanceCompany[] = [];
  maintenanceCompanyInserted = new BehaviorSubject<MaintenanceCompany>(null);
  maintenanceCompanyUpdated = new BehaviorSubject<MaintenanceCompany[]>([]);

  private _maintenanceAccounts: MaintenanceAccount[] = [];
  maintenanceAccountUpdated = new BehaviorSubject<MaintenanceAccount[]>([]);


  constructor(private _http: HttpClient) { }

  getMaintenanceCompanyObject(companyId: number): MaintenanceCompany {
    return this._maintenanceCompanies.filter(company => company.id === companyId)[0];
  }

  updateMaintenanceCompanyObject(newObjcet: MaintenanceCompany): MaintenanceCompany[] {
    const updateItem = this._maintenanceCompanies.find(company => company.id === newObjcet.id);
    const index = this._maintenanceCompanies.indexOf(updateItem);
    this._maintenanceCompanies[index] = newObjcet;
    return this._maintenanceCompanies;
  }

  private _deleteMaintenanceAccountId(index: number): MaintenanceAccount[] {
    this._maintenanceAccounts.splice(index, 1);
    return this._maintenanceAccounts;
  }

  public insertMaintenanceCompany(companyObject: MaintenanceCompany): Observable<MaintenanceCompany> {
    return this._http.put<MaintenanceCompany>(environment.serverUrl + EndpointRoutes.INSERT_MAINTENANCE_COMPANY, companyObject).pipe(
      map(responseData => {
        this._maintenanceCompanies.push(responseData);
        this.maintenanceCompanyInserted.next(responseData);
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public updateMaintenanceCompany(companyObject: MaintenanceCompany): Observable<MaintenanceCompany> {
    return this._http.post<MaintenanceCompany>(environment.serverUrl + EndpointRoutes.UPDATE_MAINTENANCE_COMPANY, companyObject).pipe(
      map(responseData => {
        const result = this.updateMaintenanceCompanyObject(responseData);
        this.maintenanceCompanyUpdated.next(result);
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public getMaintenanceCompanies(orgId: string): Observable<MaintenanceCompany[]> {
    let params = new HttpParams();
    params = params.append('organizationId', orgId);
    return this._http.get<MaintenanceCompany[]>(environment.serverUrl + EndpointRoutes.GET_MAINTENANCE_COMPANIES, { params: params }).pipe(
      map(responseData => {
        this._maintenanceCompanies = responseData;
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public insertMaintenanceAccount(companyObject: MaintenanceAccount): Observable<MaintenanceAccount> {
    return this._http.put<MaintenanceAccount>(environment.serverUrl + EndpointRoutes.INSERT_MAINTENANCE_ACCOUNT, companyObject).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }
  public updateMaintenanceAccount(companyObject: MaintenanceAccount): Observable<MaintenanceAccount> {
    return this._http.post<MaintenanceAccount>(environment.serverUrl + EndpointRoutes.UPDATE_MAINTENANCE_ACCOUNT, companyObject).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }


  public uploadImage(image: File, accountId: string, orgId: string, type: string) {
    const formData = new FormData();
    formData.append('organizationId', orgId);
    formData.append('maintenanceUserId', accountId);
    formData.append('type', type);
    formData.append('image', image);
    return this._http.post<any>(environment.serverUrl + EndpointRoutes.UPLOAD_IMAGE, formData).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public getMaintenanceAccount(accountId: string): Observable<MaintenanceAccount> {
    let params = new HttpParams();
    params = params.append('id', accountId);
    return this._http.get<MaintenanceAccount>(environment.serverUrl + EndpointRoutes.GET_MAINTENANCE_ACCOUNT, { params: params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public getMaintenanceAccounts(organizationId: string): Observable<MaintenanceAccount[]> {
    let params = new HttpParams();
    params = params.append('organizationId', organizationId);
    return this._http.get<MaintenanceAccount[]>(environment.serverUrl + EndpointRoutes.GET_MAINTENANCE_ACCOUNTS, { params: params }).pipe(
      map(responseData => {
        this._maintenanceAccounts = responseData;
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public softDeleteMaintenanceAccount(accountId: string, arrayIndex: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', accountId);
    return this._http.delete<any>(environment.serverUrl + EndpointRoutes.DELETE_MAINTENANCE_ACCOUNT, { params: params }).pipe(
      map(responseData => {
        this._deleteMaintenanceAccountId(arrayIndex);
        this.maintenanceAccountUpdated.next(this._maintenanceAccounts.slice());
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  public resetMaintenanceAccountPassword(accountId: string): Observable<{ password: string }> {
    return this._http.put<{ password: string }>(environment.serverUrl + EndpointRoutes.RESET_PASSWORD_MAINTENANCE_ACCOUNT,
      { id: accountId }).pipe(
        map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

}
