import { Pipe, PipeTransform } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { Country } from '../shared.service';

@Pipe({
  name: 'country'
})
export class CountryPipe implements PipeTransform {

  constructor(private _localStorageService: LocalStorageService) { }

  transform(countryId: number, key: string): Country | string {
    if (countryId) {
      const countries: Country[] = this._localStorageService.getAppLocalStorageObject().countries;
      const filteredCountry = countries.filter(country => country.id === countryId)[0];
      return filteredCountry[key];
    } else {
      return 'N/D';
    }
  }

}
