import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MaintenanceCompany, MaintenanceService } from '../maintenance.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-update-maintenance-company-dialog',
  templateUrl: './update-maintenance-company-dialog.component.html',
  styleUrls: ['./update-maintenance-company-dialog.component.css']
})
export class UpdateMaintenanceCompanyDialogComponent {

  @ViewChild('maintenanceCompanyForm', { static: false }) maintenanceCompanyForm: NgForm;
  companyFormGroup: FormGroup;
  public maxBirthDay = new Date();

  constructor(
    public dialogRef: MatDialogRef<UpdateMaintenanceCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _companyObject: { companyId: number, organizationId: number }, private _formBuilder: FormBuilder,
    private _maintenanceService: MaintenanceService, private _snackBar: MatSnackBar, private _translate: TranslateService) {
    this.companyFormGroup = this._formBuilder.group({
      id: [''],
      name: ['', <any>Validators.required],
      caPart145Number: ['', <any>Validators.required],
      caPart145ExpiryDate: ['', <any>Validators.required],
      organizationId: [this._companyObject.organizationId, <any>Validators.required]
    });
    if (this._companyObject.companyId) {
      this.companyFormGroup.patchValue(this._maintenanceService.getMaintenanceCompanyObject(this._companyObject.companyId));
    }
  }

  onFormSubmit() {
    if (this.companyFormGroup.valid) {
      const object = this.companyFormGroup.value as MaintenanceCompany;
      object.caPart145ExpiryDate = moment(object.caPart145ExpiryDate).format('YYYY-MM-DD');
      let observ: Observable<MaintenanceCompany>;
      if (this._companyObject.companyId) {
        observ = this._maintenanceService.updateMaintenanceCompany(object);
      } else {
        observ = this._maintenanceService.insertMaintenanceCompany(object);
      }
      observ.subscribe(result => {
        this.dialogRef.close();
        this._snackBar.open(this._translate.instant('GENERAL.savedSuccessMsg'), this._translate.instant('GENERAL.close'), {
          duration: 3000,
        });
      });
    }
  }
}
