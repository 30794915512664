import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { LocalStorageService } from 'src/app/shared/local-storage.service';

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.css']
})
export class NavListComponent implements OnInit {

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onLinkClick: EventEmitter<null> = new EventEmitter<null>();
  public userSessionObject;

  constructor(private _localStorageService: LocalStorageService, private _loginService: LoginService) { }

  ngOnInit(): void {
    this._localStorageService.userLocalStorageInserted.subscribe(result => {
      this.userSessionObject = result;
    });
    this.userSessionObject = this._localStorageService.getUserLocalStorageObject();
  }

  triggerLinkClick() {
    // this.onLinkClick.emit();
  }

  doUserLogout() {
    this._loginService.logout();
  }

}
