import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { MaterialDesignModule } from './material-design.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TopToolbarComponent } from './top-toolbar/top-toolbar.component';
import { ErrorInterceptor } from './shared/_helpers/error.interceptor';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { JwtInterceptor } from './shared/_helpers/jwt.interceptor';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { UpdatePilotComponent } from './pilot/update-pilot/update-pilot.component';
import { UpdateHelicopterComponent } from './helicopter/update-helicopter/update-helicopter.component';
import { SharedService } from './shared/shared.service';
import { NavListComponent } from './app-layout/nav-list/nav-list.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { UpdateOrganizationComponent } from './organization/update-organization/update-organization.component';
import { UpdateProfileComponent } from './profile/update-profile/update-profile.component';
import { CountryPipe } from './shared/pipes/country.pipe';
import { PlainPasswordDialogComponent } from './shared/plain-password-dialog/plain-password-dialog.component';
import { PilotRolePipe } from './shared/pipes/pilot-role.pipe';
import { UpdateMaintenanceComponent } from './maintenance/update-maintenance/update-maintenance.component';
import { MaintenanceListComponent } from './maintenance/maintenance-list/maintenance-list.component';
import { UpdateMaintenanceCompanyDialogComponent } from './maintenance/update-maintenance-company-dialog/update-maintenance-company-dialog.component';
import { FreelancerStatusPipe } from './shared/pipes/freelancer-status.pipe';
import { UpdateHelicopterOperatorDialogComponent } from './helicopter-operator/update-helicopter-operator-dialog/update-helicopter-operator-dialog.component';
import { PilotComponent } from './pilot/pilot.component';
import { HelicopterComponent } from './helicopter/helicopter.component';
import { SubPageTitleComponent } from './shared/sub-page-title/sub-page-title.component';
import { GridModule } from '@progress/kendo-angular-grid';




// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TopToolbarComponent,
    DashboardComponent,
    AppLayoutComponent,
    LoadingSpinnerComponent,
    UpdatePilotComponent,
    UpdateHelicopterComponent,
    NavListComponent,
    ConfirmationDialogComponent,
    UpdateOrganizationComponent,
    UpdateProfileComponent,
    CountryPipe,
    PlainPasswordDialogComponent,
    PilotRolePipe,
    UpdateMaintenanceComponent,
    MaintenanceListComponent,
    UpdateMaintenanceCompanyDialogComponent,
    FreelancerStatusPipe,
    UpdateHelicopterOperatorDialogComponent,
    PilotComponent,
    HelicopterComponent,
    SubPageTitleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    MaterialDesignModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    SharedService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
